<template>
  <a-card :bodyStyle="{ padding: 50 }">
    <template #title>
      打开发票
    </template>
    <a-form ref="formRef" layout="vertical" :model="form" :rules="rules">
      <a-form-item label="开票主体" :wrapperCol="{ flex: 'auto' }" :name="['invoiceCarrier']">
        <a-select v-model:value="form.invoiceCarrier" placeholder="请选择开票主体" @change="carrierChange">
          <a-select-option v-for="item in carrierList" :key="item.id" :value="item.label">{{ item.label }}</a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="税率" :wrapperCol="{ flex: 'auto' }" :name="['taxRate']">
        <a-select :disabled="form.invoiceCarrier?false:true" @change="taxChange" v-model:value="form.taxRate" placeholder="选择税率" allowClear>
          <a-select-option v-for=" item in taxRateList" :key="item.id" :lable="item.taxItem" :value="item.label">{{ item.label }}</a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="税目" :wrapperCol="{ flex: 'auto' }">
        <a-select :disabled="true" v-model:value="form.taxItem" placeholder="选择税率" allowClear>
        </a-select>
      </a-form-item>

      <a-row :gutter="16">
        <a-col :span="24">
          <a-form-item label="原因" :name="['reason']">
            <a-textarea v-model:value="form.reason" :rows="6" :maxlength="1000" showCount />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="12">
          <a-button type="primary" @click="submitText">提交</a-button>
        </a-col>
      </a-row>
    </a-form>
  </a-card>
</template>
<script>
import { reactive, toRefs, ref } from 'vue'
import { orderPickEdit } from '@/api/trans/ops/edit.js'
import { getSubjectList } from '@/api/trans/order'
import { message } from 'ant-design-vue'
import { onMounted } from 'vue'
export default {
  props: { orderId: { type: String, default: '' }, subType: {}, label: { type: String, default: '' } },
  emits: ['detail'],
  setup (props, { emit }) {
    const carrierList = ref([])//发票主体列表
    const taxRateList = ref([]) //税率列表
    const state = reactive({
      loading: false,
      
      form: {
        orderId: props.orderId,
        invoiceCarrier: '',
        taxRate: '',
        taxItem: '',
        reason: '',
      },
      rules: {
        invoiceCarrier: { required: true, message: '请选择承运公司', trigger: 'change', type: 'string' },
        taxRate: { required: true, message: '请选择税率', trigger: 'change', type: 'string' },
        reason:{ required: true, message: '请填写原因', trigger: 'change', type: 'string'}
      },
      empList: [],
    })
    const editParam = {
      params: state.form,
      type: {
        label: '运单修改',
        value: 0
      },
      subType: props.subType
    }
    const formRef = ref(null)
    const submitText = () => {
      formRef.value
        .validate()
        .then(() => {
          let form = JSON.parse(JSON.stringify(state.form))
          taxRateList.value.forEach( item => {
            if(form.taxRate == item.label) {
              form.taxRate = item.taxRate
            }
          })
          orderPickEdit({
            params: form,
            type: {
              value: 0
            },
            reason: form.reason,
            subType: props.subType
          }).then(res => {
            if (res.code === 10000) {
              message.success(res.msg)
              state.form.reason = ''
              emit('success', false)
            }
          })
        })
    }
    const carrierChange = (e, v) => {
      reqGetSubjectList(v.key)
      state.form.invoiceCarrier = v.value
      state.form.taxRate = ''
      state.form.taxItem = ''
    }
    const taxChange = (e, v) => {
      state.form.taxRate = v.value
      state.form.taxItem = v.lable
    }
    //获取主体列表
    const reqGetSubjectList = (id, name) => {
      getSubjectList(id).then(res => {
        if (id == 0) {
          res.data.forEach(item => {
            item.label = item.subjectName
            if (item.subjectName == name) {
              reqGetSubjectList(item.id)
            }
          })
          carrierList.value = res.data
        } else {
          res.data.forEach(item => {
            item.label = item.subjectName + `【${item.taxItem}】`
          })
          taxRateList.value = res.data
        }
      })
    }

    onMounted(() => {
      reqGetSubjectList(0, props.label)
      if (props.label) {
        state.form.invoiceCarrier = props.label
      }
    })
    return {
      ...toRefs(state),
      editParam,
      submitText,
      carrierList,
      carrierChange,
      taxRateList,
      taxChange,
      formRef
    }

  }

}
</script>
<style lang="less" scoped>
.ant-table td {
  white-space: nowrap;
}

.not-archive {
  color: #999;
}
</style>
