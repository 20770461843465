<template>
  <a-card :bodyStyle="{ padding: 50 }">
    <template #title>
      修改承运公司
    </template>
    <a-form ref="formRef" layout="vertical" :model="form" :rules="rules">
      <a-form-item label="承运公司" :wrapperCol="{ flex: 'auto' }">
        <a-select v-model:value="carrierType" style="width: 300px" placeholder="请选择承运商">
          <a-select-option :value="1">华月物流有限公司</a-select-option>
          <a-select-option :value="2">华月恒宝科技有限公司</a-select-option>
          <a-select-option :value="4">四川久智车途科技有限公司</a-select-option>
          <!-- <a-select-option :value="3">深圳华雨物流有限公司</a-select-option> -->
        </a-select>
      </a-form-item>
      <a-row :gutter="16">
        <a-col :span="24">
          <a-form-item label="原因">
            <a-textarea v-model:value="reason" :rows="6" :maxlength="1000" showCount />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="12">
          <a-button type="primary" @click="submitText">提交</a-button>
        </a-col>
      </a-row>
    </a-form>
  </a-card>
</template>
<script>
import { reactive, toRefs } from 'vue'
import { orderPickEdit } from '@/api/trans/ops/edit.js'
import { message } from 'ant-design-vue'
export default {
  props: { orderId: { type: String, default: '' }, subType: {} },
  emits: ['detail'],
  setup (props, { emit }) {
    const state = reactive({
      reason: '',
      orderId: props.orderId,
      carrierType: ''
    })
    const submitText = () => {
      if (state.carrierType !== '') {
        orderPickEdit({
          type: {
            value: 0
          },
          subType: props.subType,
          reason: state.reason,
          params: {
            orderId: state.orderId,
            carrierType: state.carrierType,
          }
        }).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            state.reason = ''
            emit('success', false)
          }
        })
      } else message.error('请选择承运公司')
    }
    return {
      ...toRefs(state),
      submitText
    }
  }
}
</script>
<style lang="less" scoped></style>
